import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Galerie</h2>
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Lorem Ipsum</h4>
                      </div> */}
                      <img
                        src="img/portfolio/01.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Adipiscing Elit</h4>
                      </div> */}
                      <img
                        src="img/portfolio/02.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Lorem Ipsum</h4>
                      </div> */}
                      <img
                        src="img/portfolio/03.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Lorem Ipsum</h4>
                      </div> */}
                      <img
                        src="img/portfolio/04.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Adipiscing Elit</h4>
                      </div> */}
                      <img
                        src="img/portfolio/05.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Dolor Sit</h4>
                      </div> */}
                      <img
                        src="img/portfolio/06.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Dolor Sit</h4>
                      </div> */}
                      <img
                        src="img/portfolio/07.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Lorem Ipsum</h4>
                      </div> */}
                      <img
                        src="img/portfolio/08.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                      {/* <div className="hover-text">
                        <h4>Adipiscing Elit</h4>
                      </div> */}
                      <img
                        src="img/portfolio/09.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
