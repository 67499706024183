import React, { Component } from "react";
import Banner from 'react-js-banner';

export class Header extends Component {
  state = {
    banner3Css: { color: "#FFF", backgroundColor: "red", fontSize: 20 }
  }
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    Spadauto<span className="logo-red">pieces</span>
                  </h1>
                  <p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                  {/* <Banner 
                    title= 'FERMETURE DU MAGASIN DU LUNDI 27 DÉCEMBRE AU DIMANCHE 2 JANVIER POUR CONGÉS, RÉOUVERTURE LE LUNDI 3 JANVIER'
                    css={this.state.banner3Css}
                  /> */}
                  <p>
                    Magasin ouvert du lundi au vendredi de 9h00 à 12h30 et de 14h00 à 17h30. <br />
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfeGDzPpHGgeuzHgQSvIm0_6UqQ8FMkVB1LgX0Si2yd2UAWnA/viewform"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Demandez votre devis
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
