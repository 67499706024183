import React, { Component } from "react";
import axios from 'axios';


export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
    }

    handleSubmit(e){
      e.preventDefault();
      axios({
        method: "POST", 
        url:"http://localhost:3002/send", 
        data:  this.state
      }).then((response)=>{
        if (response.data.status === 'success'){
          alert("Message Sent."); 
          this.resetForm()
        }else if(response.data.status === 'fail'){
          alert("Message failed to send.")
        }
      })
    }
  
    resetForm(){
      
       this.setState({name: '', email: '', message: ''})
    }

  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            {/* <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contactez-nous</h2>
                  <p>
                    Merci de compléter le formulaire de contact ci-dessous.
                    Nous reviendrons vers vous le plus rapidement possible.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Envoyer
                  </button>
                </form>
              </div>
            </div> */}
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contact</h2>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11330.175421953065!2d-0.5780489!3d44.7697167!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x11e5481444b138e5!2sSPADAUTOPIECE!5e0!3m2!1sfr!2sfr!4v1599838074811!5m2!1sfr!2sfr" width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0" title="Spadautopièces"></iframe>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adresse
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-clock-o"></i> Horaires d'ouverture
                  </span>{" "}
                  {this.props.data ? this.props.data.hours : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Téléphone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.maps : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href= "https://g.page/spadautopieces/review?gm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020 Spadautopièces. Design by{" "}
              Gucalvi
            </p>
            <p>
              Mentions légales :<br/>
              SPADAUTOPIECES, 2 rue Jean Bonnardel, 33140 Villenave-d'Ornon<br/>
              0540715860 / spadauto33@gmail.com<br/>
              Siret : 75284486000011 RCS BORDEAUX<br/>
              TVA Intra FR75752844860 APE 4532 Z<br/>
              SARL au capital de 10 500€
            </p>
          </div>
        </div>
      </div>
    );
  }

  onNameChange(event) {
    this.setState({name: event.target.value})
    }
  
    onEmailChange(event) {
    this.setState({email: event.target.value})
    }
  
    onMessageChange(event) {
    this.setState({message: event.target.value})
    }

}

export default Contact;
